<template>
  <AppDialog :is-open="isDialogOpened"
             :close-button="false"
             size="l"
             hide-footer
  >
    <template v-if="!showDoNotRemindMeStep" #title>
      <h2 class="primary--text mx-auto">{{ $t(`${TRANSLATION_KEY_PREFIX}.title`, { userFirstName }) }}</h2>
    </template>

    <template #body>
      <div v-if="showDoNotRemindMeStep" class="text-center">
        <h2 class="my-4 font-weight-bold">
          {{$t(`${TRANSLATION_KEY_PREFIX}.doNotRemindMeStepTitle`) }}
        </h2>
        <i18n :path="`${TRANSLATION_KEY_PREFIX}.doNotRemindMeStepText`" tag="p" scope="global">
          <template #menuItem>
            <span class="font-weight-bold">{{ $t('common.app-bar.ProfileMenuItem.profile') }}</span>
          </template>
        </i18n>
      </div>
      <template v-else>
        <p class="text-center">{{ $t(`${TRANSLATION_KEY_PREFIX}.description`) }}</p>
        <v-row>
          <v-col cols="12" md="6">
            <AppCard
              hover
              class="h-100"
              @click.native="wannaSign"
            >
              <v-row>
                <v-col
                  order="1"
                  order-md="2"
                  md="12"
                  :class="{'text-center': $vuetify.breakpoint.mdAndUp}"
                >
                  <p class="mb-2" style="font-size: 18px; font-weight: 600;">
                    {{$t(`${TRANSLATION_KEY_PREFIX}.signOptionTitle`) }}
                  </p>
                  <p>{{ $t(`${TRANSLATION_KEY_PREFIX}.signOptionDescription`) }}</p>
                </v-col>
                <v-col
                  cols="auto"
                  md="12"
                  order="2"
                  order-md="1"
                  :class="{'text-center mb-3': $vuetify.breakpoint.mdAndUp}"
                >
                  <img src="/img/file_signature_on.svg" width="95" />
                </v-col>
              </v-row>
            </AppCard>
          </v-col>
          <v-col cols="12" md="6">
            <AppCard
              hover
              class="h-100"
              @click.native="dontRemindMe"
            >
              <v-row>
                <v-col
                  order="1"
                  order-md="2"
                  md="12"
                  :class="{'text-center': $vuetify.breakpoint.mdAndUp}"
                >
                  <p class="mb-2" style="font-size: 18px; font-weight: 600;">
                    {{$t(`${TRANSLATION_KEY_PREFIX}.doNotRemindMeOptionTitle`) }}
                  </p>
                  <p>{{ $t(`${TRANSLATION_KEY_PREFIX}.doNotRemindMeOptionDescription`) }}</p>
                </v-col>
                <v-col
                  cols="auto"
                  md="12"
                  order="2"
                  order-md="1"
                  :class="{'text-center mb-3': $vuetify.breakpoint.mdAndUp}"
                >
                  <img src="/img/file_signature_off.svg" width="95" />
                </v-col>
              </v-row>
            </AppCard>
          </v-col>
        </v-row>
        <v-row>
          <AppButton
            class="mx-auto"
            color="primary"
            text
            @click="remindLater"
          >
            {{$t(`${TRANSLATION_KEY_PREFIX}.remindMeLater`)}}
          </AppButton>
        </v-row>
      </template>
    </template>

    <template v-if="showDoNotRemindMeStep" #footer>
      <AppButton @click="showDoNotRemindMeStep = false">
        <i
          v-if="$vuetify.breakpoint.mdAndUp"
          class="fal mr-2 fa-arrow-left-long"
        ></i>
        {{ $t('common.previous') }}
      </AppButton>
      <v-spacer />
      <AppButton
        color="primary"
        @click="closeDialog"
      >
        {{ $t('common.close') }}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppCard from '@/common/AppCard.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { UPDATE_PROFILE } from '@/store/modules/user/action_types'
import { SET_SHOW_IDENTITY_CHECK_MODAL } from '@/store/modules/user/mutation_types'

const TRANSLATION_KEY_PREFIX = 'common.identity-checking.IdentityCheckingLandingDialog'

const { mapMutations, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'IdentityCheckingLandingDialog',
  components: { AppDialog, AppButton, AppCard },
  mixins: [dialogMixin],
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      showDoNotRemindMeStep: false,
    }
  },
  computed: {
    userFirstName () {
      return this.$store.state.user.profile.firstName
    },
  },
  watch: {
    showDoNotRemindMeStep (value) {
      // If we get to a falsy value again, that means we got back from
      // The do not remind me step. So we should put the hereToSign back to true
      if (!value) {
        this.UPDATE_PROFILE({ hereToSign: 1 })
      }
    },
  },
  methods: {
    ...mapActions([UPDATE_PROFILE]),
    ...mapMutations([SET_SHOW_IDENTITY_CHECK_MODAL]),
    wannaSign () {
      this.closeDialog()
      this.SET_SHOW_IDENTITY_CHECK_MODAL(true)
    },
    remindLater () {
      this.closeDialog()
    },
    dontRemindMe () {
      this.UPDATE_PROFILE({ hereToSign: 0 })
      this.showDoNotRemindMeStep = true
    },
  },
}
</script>
