<template>
  <div :style="gridStyles" data-locator="access-rights-gobal-row">
    <div
      class="AccessRightsTable-memberGridCell sticky-left font-weight-medium d-flex align-center">
      {{ $t('project.project-settings.access-rights.AccessRightsGlobal.everybody') }}
    </div>
    <div v-for="column in displayedColumns"
         :key="`column-${column.propertyName}`"
         class="AccessRightsTable-memberGridCell"
    >
      <v-checkbox :input-value="roomRights.global[column.propertyName]"
                  :disabled="isCheckboxDisabled(column)"
                  dense
                  hide-details
                  class="AccessRightsTable-checkbox"
                  :data-locator="rightToLocatorValue(column.propertyName)"
                  @change="patchGlobalRights(column.propertyName, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { toKebabCase } from '@/common/utils/strings'
import { PATCH_ROOM_GLOBAL_RIGHTS } from '@/store/modules/room/action_types'

export default {
  name: 'AccessRightsGlobal',
  props: {
    gridStyles: {
      type: Object,
      required: true,
    },
    patchRightsPending: {
      type: Boolean,
      required: true,
    },
    displayedColumns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('room', ['roomRights']),
    ...mapGetters('room', ['isDataRoom', 'roomMnemo']),
  },
  methods: {
    ...mapActions('room', [PATCH_ROOM_GLOBAL_RIGHTS]),
    async patchGlobalRights (property, value) {
      try {
        await this.PATCH_ROOM_GLOBAL_RIGHTS({
          mnemo: this.roomMnemo,
          data: {
            [property]: value,
          },
        })
        this.$emit('patchRoomRightsSuccess')
      } catch (error) {
        this.$emit('patchRoomRightsError')
      }
    },
    isCheckboxDisabled (column) {
      if (column.disableCheck?.global) {
        return column.disableCheck.global()
      }

      return this.patchRightsPending
    },
    rightToLocatorValue (property) {
      return toKebabCase(property) + '-checkbox'
    },
  },
}
</script>
