<template>
  <AppDialog :is-open="isDialogOpened"
             size="auto"
             :component-attrs="{
                'content-class': 'ChecklistTableStatusDialog',
             }"
             @cancel="checkModificationsBeforeClose"
  >
    <template #title>{{ $t('project.checklist.task.ChecklistTableStatusDialog.title') }} {{ task.display }}</template>
    <template #subTitle>{{ $t('project.checklist.task.ChecklistTableStatusDialog.subtitle') }}</template>
    <template #body>
      <template v-if="taskStatusCategoriesPending">
        <div class="text-center">
          <AppLoader/>
        </div>
      </template>
      <template v-if="statusCategoriesList.length > 0">
        <div class="ChecklistTableStatusDialog__categories">
          <ChecklistTableStatusCategory v-for="statusCategory in statusCategoriesList"
                                        :key="`category-${statusCategory.name}`"
                                        :category="statusCategory"
                                        :edit-mode="editMode"
                                        :selected-status-id.sync="selectedStatusId"
                                        @status-edit="onStatusEdit"
                                        @status-delete="onStatusDelete"
          />
        </div>
      </template>
    </template>
    <template #footer>
      <div class="w-100">
        <!-- Send notif -->
        <v-checkbox v-model="taskStatusSendNotif"
                    class="d-inline-block"
                    :label="$t('project.checklist.task.ChecklistTableStatusDialog.sendNotifLabel')"
                    hide-details
        />

        <div class="ChecklistTableStatusDialog__buttons d-flex mt-3">
          <AppButton v-if="editMode"
                    class="action-buttons"
                    outlined
                    :disabled="isTaskEditionPending "
                    @click="cancelModifications"
          >
            {{ $t('project.checklist.task.ChecklistTableStatusDialog.cancelModificationsBtnText') }}
          </AppButton>
          <AppButton v-else
                    class="action-buttons"
                    outlined
                    :disabled="isTaskEditionPending "
                    @click="closeDialog"
          >
            {{ $t('project.checklist.task.ChecklistTableStatusDialog.cancelBtnText') }}
          </AppButton>

          <v-spacer />

          <template v-if="canCurrentUserEditProjectStatuses">
            <AppButton v-if="editMode"
                      color="primary"
                      :loading="postStatusPending"
                      :disabled="isTaskEditionPending "
                      @click="preparePostStatus"
            >
              {{ $t('project.checklist.task.ChecklistTableStatusDialog.saveStatus') }}
            </AppButton>
            <AppButton v-else
                      class="action-buttons"
                      outlined
                      :disabled="isTaskEditionPending "
                      @click="editMode = true"
            >
              {{ $t('project.checklist.task.ChecklistTableStatusDialog.editStatus') }}
            </AppButton>
          </template>

          <AppTooltip top :disabled="!validateIsDisabled">
            <template #activator="{attrs, on}">
              <span v-on="on">
                <AppButton color="primary"
                          v-bind="attrs"
                          class="w-100"
                          :disabled="validateIsDisabled"
                          :loading="isTaskEditionPending "
                          @click="preparePatchTask"
                >
                  {{ $t('common.validate') }}
                </AppButton>
              </span>
            </template>
            <span>{{ $t('project.checklist.task.ChecklistTableStatusDialog.validateBtnTooltip') }}</span>
          </AppTooltip>
        </div>
      </div>

      <ChecklistTableStatusCloseDialog v-if="isBeforeCloseDialogOpen"
                                       @confirm="closeModalWhithoutSaving"
                                       @close="isBeforeCloseDialogOpen = false"
      />
    </template>
  </AppDialog>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import { dialogMixin } from '@/mixins/dialogMixin'
import ChecklistTableStatusCloseDialog from '@/project/checklist/task/ChecklistTableStatusCloseDialog.vue'
import { statusCategories } from '@/project/checklist/task/taskStatus'
import {
  EDIT_TASK,
  GET_CHECKLIST,
  GET_TASK_STATUS_CATEGORIES,
  POST_STATUS,
} from '@/store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import ChecklistTableStatusCategory from './ChecklistTableStatusCategory'
import AppLoader from '../../../common/AppLoader'
import AppTooltip from '../../../common/AppTooltip.vue'
import AppButton from '../../../common/buttons/AppButton'
import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'ChecklistTableStatusDialog',
  components: {
    AppButton,
    AppDialog,
    AppLoader,
    AppTooltip,
    ChecklistTableStatusCategory,
    ChecklistTableStatusCloseDialog,
  },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isBeforeCloseDialogOpen: false,
      editMode: false,
      internalStatusCategories: null,
      selectedStatusId: this.task.statusId,
      taskStatusSendNotif: true,
    }
  },
  computed: {
    ...mapState('checklist', ['editTaskPending', 'taskStatusCategories', 'taskStatusCategoriesPending', 'postStatusPending']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    canCurrentUserEditProjectStatuses () {
      return this.isCurrentUserPm
    },
    currentUserIsResponsibleOfTheTask () {
      return !!this.task.rights.tagged.users.find(user => user.id === this.currentUserId)
    },
    currentUserIsValidatorOfTheTask () {
      return !!this.task.rights.validators.users.find(user => user.id === this.currentUserId)
    },
    statusCategoriesList () {
      if (!this.internalStatusCategories) {
        return []
      }

      return Object.entries(this.internalStatusCategories).map(
        ([category, statuses]) => ({
          name: category,
          statuses: statuses,
          areStatusesSelectable: this.areCategoryStatusesSelectable(category),
        }),
      )
    },
    isTaskEditionPending  () {
      return this.editTaskPending === this.task.id
    },
    haveTaskStatusesModifications () {
      return this.selectedStatusId !== this.task.statusId || JSON.stringify(this.internalStatusCategories) !== JSON.stringify(this.taskStatusCategories)
    },
    validateIsDisabled () {
      return this.selectedStatusId === this.task.statusId || this.editMode
    },
  },
  watch: {
    taskStatusCategories (categories) {
      this.internalStatusCategories = cloneDeep(categories)
    },
  },
  created () {
    this.prepareGetTaskStatuses()
  },
  methods: {
    ...mapActions('checklist', [
      EDIT_TASK,
      GET_CHECKLIST,
      GET_TASK_STATUS_CATEGORIES,
      POST_STATUS,
    ]),
    async prepareGetTaskStatuses () {
      try {
        await this.GET_TASK_STATUS_CATEGORIES({ mnemo: this.mnemo })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableStatusDialog.getTaskStatusesError'),
        })
      }
    },
    async preparePatchTask () {
      try {
        await this.EDIT_TASK({
          parent: this.parent,
          mnemo: this.mnemo,
          taskId: this.task.id,
          data: {
            statusId: this.selectedStatusId,
            sendNotif: this.taskStatusSendNotif,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.task.ChecklistTableStatusDialog.editTaskSuccess'),
        })
        this.closeDialog()
      } catch (error) {
        if (error?.response?.data?.errorCode === 'ERR_INVALID_RIGHTS') {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableStatusDialog.editTaskErrorInvalidRights'),
          })
        } else {
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('project.checklist.task.ChecklistTableStatusDialog.editTaskGenericError'),
          })
        }
      }
    },
    async preparePostStatus () {
      let postStatusIsSuccessful = false

      try {
        postStatusIsSuccessful = await this.POST_STATUS({
          mnemo: this.mnemo,
          data: { statuses: this.internalStatusCategories },
        })
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.task.ChecklistTableStatusDialog.postStatusError'),
        })
      } finally {
        this.editMode = false
      }

      if (postStatusIsSuccessful) {
        this.GET_CHECKLIST(this.mnemo)
      }
    },
    areCategoryStatusesSelectable (category) {
      return this.isCurrentUserPm ||
        this.currentUserIsValidatorOfTheTask ||
        (
          this.currentUserIsResponsibleOfTheTask &&
          [statusCategories.DRAFT, statusCategories.IN_PROGRESS, statusCategories.HELD].includes(category)
        )
    },
    cancelModifications () {
      this.editMode = false
      this.internalStatusCategories = cloneDeep(this.taskStatusCategories)
    },
    checkModificationsBeforeClose () {
      if (this.haveTaskStatusesModifications) {
        this.isBeforeCloseDialogOpen = true
      } else {
        this.closeDialog()
      }
    },
    closeModalWhithoutSaving () {
      this.isBeforeCloseDialogOpen = false
      this.closeDialog()
    },
    onStatusDelete ({ status, category }) {
      const statusIndex = this.internalStatusCategories[category].findIndex(s => s.id === status.id)
      if (statusIndex >= 0) {
        this.internalStatusCategories[category].splice(statusIndex, 1)
      }
    },
    onStatusEdit ({ editedCategory, editedStatus }) {
      const statusToEditIndex = this.internalStatusCategories[editedCategory].findIndex(
        status => status.id === editedStatus.id,
      )

      if (statusToEditIndex < 0) {
        return
      }

      if (editedStatus.category === editedCategory) {
        this.internalStatusCategories[editedCategory][statusToEditIndex].label = editedStatus.label
        this.internalStatusCategories[editedCategory][statusToEditIndex].color = editedStatus.color
      } else {
        const statusToEdit = this.internalStatusCategories[editedCategory][statusToEditIndex]
        this.internalStatusCategories[editedStatus.category].push({
          ...cloneDeep(statusToEdit),
          label: editedStatus.label,
          color: editedStatus.color,
        })
        this.internalStatusCategories[editedCategory].splice(statusToEditIndex, 1)
      }
    },
  },
}
</script>

<style lang="scss">
.ChecklistTableStatusDialog {
  height: 600px;
  display: flex;
  overflow: hidden;

  &__categories {
    display: flex;
    column-gap: 16px;
  }

  .v-card {
    display: flex;
    flex-direction: column;

    &__text {
      flex: 1;
      display: flex;
      overflow-y: hidden;
    }
  }

  &__buttons {
    button:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0;
    }

    @media (max-width: 500px) {
      flex-direction: column;

      button:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }

  .action-buttons {
    &.v-btn--outlined {
      border-color: var(--v-grey-lighten2);
    }
  }
}
</style>
