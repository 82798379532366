<template>
  <div class="DataRoomLayout" data-locator="dataroom-settings">
    <h1 class="text-h1 pb-4 ml-1">{{ $t("project.project-settings.layouts.DataRoomLayout.dataRoomSettings") }}</h1>
    <ProjectSettingsMainSettings :mnemo="mnemo"/>
    <ProjectSettingsAccessRights id="accessRightsContainer" class="mt-8" :mnemo="mnemo"/>
    <ProjectSettingsDataRoomInfo :mnemo="mnemo" :show-upgrade-dialog="showUpgradeDialog" class="mt-8" />
    <ProjectSettingsNotifications class="mt-8" :mnemo="mnemo"/>
  </div>
</template>

<script>
  import ProjectSettingsAccessRights from '@/project/project-settings/access-rights/AccessRights.vue'
  import ProjectSettingsNotifications from '@/project/project-settings/notifications/ProjectSettingsNotifications.vue'
  import ProjectSettingsDataRoomInfo from '@/project/project-settings/ProjectSettingsDataRoomInfo.vue'
  import ProjectSettingsMainSettings from '@/project/project-settings/ProjectSettingsMainSettings.vue'

  export default {
    name: 'DataRoomLayout',
    components: {
      ProjectSettingsAccessRights,
      ProjectSettingsNotifications,
      ProjectSettingsDataRoomInfo,
      ProjectSettingsMainSettings,
    },
    data () {
      return {
        showUpgradeDialog: false,
      }
    },
    props: {
      mnemo: {
        type: String,
        required: true,
      },
    },
    mounted () {
      if (this.$route.query?.show === 'upgradeDialog') {
        this.showUpgradeDialog = true
      }
      if (this.$route.params.goTo) {
        this.$vuetify.goTo(
          this.$route.params.goTo,
          { offset: 50 },
        )
      }
    },
  }
</script>
