import { computed, getCurrentInstance, Ref } from 'vue'

type UseDataLocatorReturn = {
  attrs: Ref<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentAttrs: Record<PropertyKey, any>
    dataLocator: string | undefined
  }>
}

export function useDataLocator (): UseDataLocatorReturn {
  const instance = getCurrentInstance()
  const attrs = computed(() => {
    const { 'data-locator': dataLocator, ...componentAttrs } = instance?.proxy?.$attrs || {}
    return {
      componentAttrs,
      dataLocator: (dataLocator as string),
    }
  })

  return { attrs }
}
