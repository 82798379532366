<template>
  <div>
    <AppDialog  content-class="import-checklist-from-template-dialog"
                :is-open="isDialogOpened"
                :tabs-template-has-title="tabsTemplateHasTitle"
                size="xxxl"
                tabs-dialog
                @cancel="resetAndClose"
    >
      <template #tabs-header>
        <h3 class="text-h3">
          {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.title') }}
        </h3>
      </template>
      <template #tabs>
        <div class="tabs-custom-header mx-4" :style="{ top: tabsTemplateHasTitle ? '60px' : 0 }">
          <v-tabs v-model="tab">
            <v-tab class="text-subtitle-1">
              {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.savedChecklists.title') }}
            </v-tab>
            <v-tab v-if="canAccessLexisNexisModels">
              <v-img class="mr-2" src="/img/logo-LexisNexis.svg"/>
              <div class="text-subtitle-1">
                {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.LexisNexisModels.title') }}
              </div>
            </v-tab>
          </v-tabs>

          <v-divider/>
        </div>

        <v-tabs-items v-model="tab" class="overflow-visible">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <SavedChecklistTableTemplate />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="canAccessLexisNexisModels">
            <v-card flat>
              <v-card-text>
                <LexisNexisChecklistTableTemplate />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template #footer>
        <div class="w-100">
          <v-divider></v-divider>
          <app-button class="ml-auto mr-4 mt-3"
                      :disabled="okDisabled"
                      @click="closeDialog(); openImportChecklistDialog()"
          >
            {{ $t('project.checklist.dialogs.SaveChecklistFromTemplateDialog.useThisTemplate') }}
          </app-button>
        </div>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import {
  SET_SELECTED_SAVED_CHECKLIST,
  SET_SELECTED_LEXISNEXIS_CHECKLIST,
  SET_CHECKLISTS_TEMPLATES_SELECTED_TAB,
  SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN,
} from '@/store/modules/checklist/mutation_types'

import { TAB_INDEX } from './enums.ts'
import LexisNexisChecklistTableTemplate from './LexisNexisChecklistTableTemplate.vue'
import SavedChecklistTableTemplate from './SavedChecklistTableTemplate.vue'
import { GET_SAVED_CHECKLISTS } from '../../../store/modules/checklist/action_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

export default {
  name: 'ImportChecklistFromTemplateDialog',
  mixins: [dialogMixin],
  components: {
    AppDialog,
    LexisNexisChecklistTableTemplate,
    SavedChecklistTableTemplate,
  },
  data () {
    return {
      TAB_INDEX,
      tab: TAB_INDEX.UNSET,
      tabsTemplateHasTitle: true,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('checklist', ['selectedSavedChecklist', 'selectedLexisNexisChecklist']),
    canAccessLexisNexisModels () {
      return this.profile.locale === 'fr' && this.profile.subscription?.accessTodoListLexis
    },
    okDisabled () {
      if (this.tab === TAB_INDEX.SAVED_CHECKLIST && !this.selectedSavedChecklist) return true
      if (this.tab === TAB_INDEX.LEXIS_NEXIS_CHECKLIST && !this.selectedLexisNexisChecklist) return true
      return false
    },
  },
  created () {
    this.loadData()
  },
  watch: {
    /**
     * The tab selected is added to the store because we want to know the origin of
     * the checklist template when we'll use this information later in the
     * Import dialog component (ImportChecklistDialog.vue)
     */
    tab (newTabIndex) {
      this.$store.commit(`checklist/${SET_CHECKLISTS_TEMPLATES_SELECTED_TAB}`, newTabIndex)
    },
  },
  methods: {
    ...mapActions('checklist', [GET_SAVED_CHECKLISTS]),
    async loadData () {
      try {
        await this.GET_SAVED_CHECKLISTS()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.dialogs.ImportChecklistDialog.getSavedChecklistsError'),
        })
      }
    },
    openImportChecklistDialog () {
      this.$store.commit(`checklist/${SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN}`, true)
    },
    resetAndClose () {
      this.$store.commit(`checklist/${SET_SELECTED_SAVED_CHECKLIST}`, null)
      this.$store.commit(`checklist/${SET_SELECTED_LEXISNEXIS_CHECKLIST}`, null)
      this.$store.commit(`checklist/${SET_CHECKLISTS_TEMPLATES_SELECTED_TAB}`, TAB_INDEX.UNSET)
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss">
  .import-checklist-from-template-dialog {
    .tabs-custom-header {
      position: sticky;
      background-color: white;
      z-index: 1;
    }

    .v-card {
      // Remove the padding to the left and right of the footer
      // so that the divider in the footer takes up the full width
      .v-card__actions {
        padding: 8px 0px 24px;
      }
    }
  }
</style>
