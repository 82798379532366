<template>
  <AppDialog
    :is-open="isDialogOpened"
    :ok-text="$t('projects.dialogs.RoomUnderNdaDialog.accessDocument').toString()"
    size="l"
    @ok="signNda"
    @cancel="closeDialog"
  >
    <template #title>
      {{ $t('projects.dialogs.RoomUnderNdaDialog.title') }}
    </template>
    <template #body>
      <app-text>{{ $t('projects.dialogs.RoomUnderNdaDialog.actionBody') }}</app-text>
      <app-text>{{ $t('projects.dialogs.RoomUnderNdaDialog.mailReminderText') }}</app-text>
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { NDA_SIGNING_VIEW_ROUTE_NAME } from '@/router'

export default defineComponent({
  name: 'RoomUnderNdaDialog',
  components: {
    AppDialog,
  },
  mixins: [dialogMixin],
  computed: {
    ...mapState('room', ['roomUnderNdaDialog']),
  },
  methods: {
    signNda () {
      this.$router.push({
        name: NDA_SIGNING_VIEW_ROUTE_NAME,
        params: {
          mnemo: this.roomUnderNdaDialog.mnemo,
        },
      })
    },
  },
})
</script>
