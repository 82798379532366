<template>
  <tr>
    <td class="fitwidth">
      <RoomTypeIcon :room="room" />
    </td>

    <td class="nameColumn text-truncate">
      <div class="d-flex align-center">
        <AppTooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <span class="font-weight-bold"
                    v-bind="attrs"
                    v-on="on">{{room.name}}</span>
          </template>
          {{room.name}}
        </AppTooltip>
        <AppTooltip v-if="room.daysUntilClosure !== undefined && room.daysUntilClosure !== null" top>
          <template #activator="{ on }">
            <app-icon
              icon-weight="fas"
              icon-name="warning"
              class="warning--text ml-1"
              v-on="on"
            />
          </template>
          {{ $tc('projects.ProjectsGrid.nbDaysBeforeClosure', room.daysUntilClosure, { nbDays: room.daysUntilClosure }) }}
        </AppTooltip>
      </div>
    </td>

    <td class="fitwidth">
      <AppTooltip top>
        <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="['fas', 'user-friends']"
            style="color: #626262"
          />
          <span>{{room.nbParticipants}}</span>
        </span>
        </template>
        {{ $tc('projects.table.ProjectsTableItem.nbParticipants', room.nbParticipants, { nbParticipants: room.nbParticipants }) }}
      </AppTooltip>
    </td>

    <td class="fitwidth">
      <AppProjectLabel v-if="room.label" :label="room.label"/>
    </td>

    <td :style="`max-width: ${PROJECT_CARD_CONTENT_WIDTH}px`">
      <AppTooltip top>
          <template v-slot:activator="{ attrs, on }">
            <div
              class="text-truncated"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ room.cmRef }}</span>
            </div>
          </template>
          <span>{{ room.cmRef }}</span>
        </AppTooltip>
    </td>

    <td class="fitwidth">
      <span>{{formattedCreatedAt}}</span>
    </td>

    <td class="fitwidth">
      <span>{{formattedExpectedClosingDate}}</span>
    </td>

    <td class="fitwidth">
      <span>{{documentsCount}}</span>
    </td>

    <td class="fitwidth">
      <span>{{qaCount}}</span>
    </td>

    <td class="fitwidth">
      <span>{{originalsCount}}</span>
    </td>

    <td class="fitwidth">
      <span>{{tasksCount}}</span>
    </td>

    <td class="fitwidth">
      <span>{{signaturesPendingCount}}</span>
    </td>
  </tr>
</template>

<script>
import AppProjectLabel from '../../common/AppProjectLabel'
import AppTooltip from '../../common/AppTooltip'
import RoomTypeIcon from '../../common/RoomTypeIcon'
import { ISOToShortenedDate } from '../../common/utils/dates'
import { getRoomCount } from '../../common/utils/rooms'
import { PROJECT_CARD_CONTENT_WIDTH } from '../ProjectsGrid.vue'

export default {
  name: 'ProjectsTableItem',
  components: { AppProjectLabel, AppTooltip, RoomTypeIcon },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PROJECT_CARD_CONTENT_WIDTH,
    }
  },
  computed: {
    formattedCreatedAt () {
      return ISOToShortenedDate(this.room.createdAt)
    },
    formattedExpectedClosingDate () {
      return ISOToShortenedDate(this.room.expectedClosingDate)
    },
    counts () {
      const { countsForRoom, countsForUser } = this.room

      if (countsForRoom) {
        return countsForRoom
      }

      if (countsForUser) {
        return countsForUser
      }

      return null
    },
    documentsCount () {
      return getRoomCount(this.room, 'files')
    },
    qaCount () {
      return getRoomCount(this.room, 'questions')
    },
    originalsCount () {
      return getRoomCount(this.room, 'originals')
    },
    tasksCount () {
      return getRoomCount(this.room, 'tasksTagged')
    },
    signaturesPendingCount () {
      return getRoomCount(this.room, 'signaturesPending')
    },
  },
}
</script>

<style scoped lang="scss">
td {
  text-align: left;
  padding: 0 24px !important;
  white-space: nowrap;

  &:first-child {
    padding-left: 16px !important;
  }

  @media (max-width: 1600px) {
    padding: 0 14px !important;
  }
}
.nameColumn {
  max-width: 300px;
}
</style>
