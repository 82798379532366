<template>
 <div :style="gridStyles" data-locator="access-rights-member-row">
   <div class="AccessRightsTable-memberGridCell sticky-left d-flex align-center ml-8"
   >
     {{ `${member.firstName} ${member.lastName}` }}
   </div>
   <div v-for="column in displayedColumns"
        :key="`member-${member.id}-column-${column.propertyName}`"
        class="AccessRightsTable-memberGridCell"
   >
     <v-checkbox :input-value="member[column.propertyName]"
                 :disabled="isCheckboxDisabled(column)"
                 dense
                 hide-details
                 class="AccessRightsTable-checkbox"
                 :data-locator="rightToLocatorValue(column.propertyName)"
                 @change="patchMemberRights(column.propertyName, $event)"
     />
   </div>
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { toKebabCase } from '@/common/utils/strings'
import { PATCH_ROOM_USER_RIGHTS } from '@/store/modules/room/action_types'

export default {
  name: 'AccessRightsMember',
  props: {
    gridStyles: {
      type: Object,
      required: true,
    },
    patchRightsPending: {
      type: Boolean,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    isGroupPm: {
      type: Boolean,
      required: true,
    },
    displayedColumns: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['isDataRoom', 'roomMnemo']),
  },
  methods: {
    ...mapActions('room', [PATCH_ROOM_USER_RIGHTS]),
    async patchMemberRights (property, value) {
      try {
        await this.PATCH_ROOM_USER_RIGHTS({
          mnemo: this.roomMnemo,
          userId: this.member.id,
          data: {
            [property]: value,
          },
        })
        this.$emit('patchRoomRightsSuccess')
      } catch (error) {
        this.$emit('patchRoomRightsError')
      }
    },
    isCheckboxDisabled (column) {
      if (column.disableCheck?.member) {
        return column.disableCheck.member(this.member, this.isGroupPm)
      }

      return this.isGroupPm || this.patchRightsPending
    },
    rightToLocatorValue (property) {
      return toKebabCase(property) + '-checkbox'
    },
  },
}
</script>
