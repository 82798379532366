<template>
  <AppChatMessage :message-date="messageDate" data-locator="summary-content">
    <template #message-author>
      <v-img
        src="/img/ai_assistant_logo.svg"
        :width="28"
        :height="28"
      />
    </template>

    <slot />

    <DocumentsAISummaryMessageActions
      v-if="summaryId && displayActions"
      :summary-id="summaryId"
      :summary-feedback="summaryFeedback"
      @copy="copySummary"
    />
  </AppChatMessage>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AppChatMessage from '@/common/app-chat/AppChatMessage.vue'

import DocumentsAISummaryMessageActions from './DocumentsAISummaryMessageActions.vue'

export default defineComponent({
  name: 'DocumentsAISummaryChatMessage',
  components: { DocumentsAISummaryMessageActions, AppChatMessage },
  props: {
    messageDate: {
      type: String,
      required: true,
    },
    summaryId: {
      type: Number,
      required: false,
    },
    summaryFeedback: {
      type: Number,
      required: false,
    },
    displayActions: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async copySummary () {
      if (this.$slots.default?.[0]?.text) {
        await navigator.clipboard.writeText(this.$slots.default?.[0]?.text)
      }
    },
  },
})
</script>
