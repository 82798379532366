<template>
  <div>
    <AppButton color="primary"
               outlined
               data-locator="close-room-button"
               @click="isOpen = true"
    >
      <font-awesome-icon :icon="['far', 'ban']" class="mr-2"></font-awesome-icon>
      {{ closeRoomButtonText }}
    </AppButton>
    <AppDialog :is-open="isOpen"
               size="l"
               :ok-text="$t('common.confirm')"
               :ok-loading="closeRoomPending"
               :ok-disabled="closeRoomPending"
               :cancel-disabled="closeRoomPending"
               data-locator="close-room-dialog"
               @ok="prepareCloseRoom"
               @cancel="resetAndClose"
    >
      <template #title>{{ $t('project.project-settings.ProjectSettingsCloseRoomDialog.closeProject') }}</template>
      <template #body>
        <app-alert-in-page
          v-if="closingInProgress"
          class="mb-6"
          variant="warning"
          :dismissible="false"
        >
          {{ $t('project.project-settings.ProjectSettingsCloseRoomDialog.closingInProgressWarning') }}
        </app-alert-in-page>
        <v-row>
          <v-col class="text--primary">
            <p class="mb-3" v-html="$t('project.project-settings.ProjectSettingsCloseRoomDialog.text1')"></p>
            <p class="mb-3" v-html="$t('project.project-settings.ProjectSettingsCloseRoomDialog.text2')"></p>
            <p class="font-weight-bold" v-html="$t('project.project-settings.ProjectSettingsCloseRoomDialog.text3', {date: deletionDate})"></p>
            <p class="mt-4">{{ $t('project.project-settings.ProjectSettingsCloseRoomDialog.notify') }}</p>
            <v-checkbox v-model="notifyPms"
                      :label="$t('project.project-settings.ProjectSettingsCloseRoomDialog.notifyPms')"
                      :disabled="forcedNotifications"
                      data-locator="notify-project-managers-checkbox"
                      hide-details
                      class="mt-3"
            />
            <v-checkbox v-model="notifyGuests"
                      :label="$t('project.project-settings.ProjectSettingsCloseRoomDialog.notifyGuests')"
                      :disabled="forcedNotifications"
                      data-locator="notify-guests-checkbox"
                      hide-details
                      class="mt-3"
            />
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import { ISOToDDMMYYYY } from '@/common/utils/dates'
import { ROOMS_ROUTE_NAME } from '@/router'
import { CLOSE_ROOM } from '@/store/modules/room/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppDialog from '../../common/dialogs/AppDialog'
export default {
  name: 'ProjectSettingsCloseRoomDialog',
  components: { AppDialog, AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isOpen: false,
      notifyPms: true,
      notifyGuests: true,
    }
  },
  computed: {
    ...mapState('room', ['currentRoom', 'closeRoomPending']),
    ...mapGetters('room', ['isDataRoom']),
    ...mapGetters('user', ['areNotificationsForcedOnRoomClose']),
    deletionDate () {
      return ISOToDDMMYYYY(dayjs().add(1, 'month'))
    },
    closeRoomButtonText () {
      return this.isDataRoom
        ? this.$t('project.project-settings.ProjectSettingsCloseRoomDialog.closeDataRoom')
        : this.$t('project.project-settings.ProjectSettingsCloseRoomDialog.closeProject')
    },
    forcedNotifications () {
      return this.areNotificationsForcedOnRoomClose
    },
    closingInProgress () {
      return this.currentRoom.inClosing
    },
  },
  methods: {
    ...mapActions('room', [CLOSE_ROOM]),
    resetAndClose () {
      this.isOpen = false
      this.notifyPms = true
      this.notifyGuests = true
    },
    async prepareCloseRoom () {
      try {
        await this.CLOSE_ROOM({
          mnemo: this.mnemo,
          data: {
            notifyPms: this.notifyPms,
            notifyGuests: this.notifyGuests,
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.project-settings.ProjectSettingsCloseRoomDialog.closeRoomSuccess'),
        })
        this.$router.push({ name: ROOMS_ROUTE_NAME })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.project-settings.ProjectSettingsCloseRoomDialog.closeRoomError'),
        })
      }
    },
  },
}
</script>
