<template>
  <div>
    <div class="body-1 font-weight-bold pb-5 ml-1">
      {{ $t('project.project-settings.ProjectSettingsFeatures.title') }}
    </div>

    <!-- CHECKLIST -->
    <AppCard class="ma-1">
      <app-text variant="medium-bold" class="mb-4">
        {{ $t('project.project-settings.ProjectSettingsFeatures.checklist.title') }}
      </app-text>

      <AppCard class="mb-2" data-locator="double-validation-checklist-card">
        <app-text variant="small-bold">
          {{ $t('project.project-settings.ProjectSettingsFeatures.checklist.doubleValidation') }}
        </app-text>

        <div class="d-flex justify-space-between align-center mt-2">
          <app-text variant="small-regular">
            {{ $t('project.project-settings.ProjectSettingsFeatures.checklist.doubleValidationLabel') }}
          </app-text>
          <v-switch :input-value="enableValidationChecklistItemDeletion"
                    readonly
                    class="mt-n1"
                    hide-details
                    :loading="patchRoomPending"
                    @click="!patchRoomPending && prepareEnableValidationChecklistItemDeletion()"
          >
          </v-switch>
        </div>
      </AppCard>
    </AppCard>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'

  import AppCard from '@/common/AppCard.vue'
  import { PATCH_ROOM } from '@/store/modules/room/action_types'
  import { SET_CURRENT_ROOM } from '@/store/modules/room/mutation_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  export default {
    name: 'ProjectSettingsFeatures',
    components: { AppCard },
    props: {
      mnemo: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState('room', ['currentRoom', 'patchRoomPending']),
      enableValidationChecklistItemDeletion () {
        return this.currentRoom?.enableValidationChecklistItemDeletion
      },
    },
    methods: {
      ...mapActions('room', [PATCH_ROOM]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      async prepareEnableValidationChecklistItemDeletion () {
        try {
          await this.PATCH_ROOM({
            mnemo: this.mnemo,
            data: {
              optEnableValidationChecklistItemDeletion: !this.enableValidationChecklistItemDeletion,
            },
          })
          this.currentRoom.enableValidationChecklistItemDeletion = !this.enableValidationChecklistItemDeletion
          this.$store.commit(`room/${SET_CURRENT_ROOM}`, this.currentRoom)

          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.project-settings.ProjectSettingsFeatures.checklist.setDoubleValidationSuccess'))
        } catch (error) {
          console.error(error)
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.project-settings.ProjectSettingsFeatures.checklist.setDoubleValidationError'))
        }
      },
    },
  }
</script>
