<template>
  <div>
    <AppDialog :is-open="isDialogOpened"
               :component-attrs="{
                  'content-class': 'signersOrderDialog',
               }"
               size="xxxl"
               @cancel="onExplicitCloseDialog"
    >
      <template #title>{{ $t('project.signing-checklist.signer-order.SignersOrderDialog.title') }}</template>

      <template #body>
        <v-row>
          <v-col>
            <div>{{ $t('project.signing-checklist.signer-order.SignersOrderDialog.info') }}</div>
          </v-col>
        </v-row>
        <v-row class="SignersOrderDialog__container">
          <v-col class="SignersOrderDialog__signers-container" cols="4">
            <v-card class="pa-4" color="#f5f5f5" flat rounded>
              <div class="font-weight-medium mb-4">{{ $t('project.signing-checklist.signer-order.SignersOrderDialog.signatoriesListTitle', { nbOfSignatories: signersToOrder.length }) }}</div>
              <div class="d-flex flex-column" style="gap: 8px">
                <SignerOrderItem v-for="signer in signersToOrder"
                                 :key="signer"
                                 :signer-id="signer"
                                 :draggable="true"
                                 :group-index="null"
                                 @dragstart="(event) => dragStart(event, signer)"
                />
              </div>
            </v-card>
          </v-col>

          <v-col class="SignersOrderDialog__groups-container" cols="8">
            <v-card v-for="(group, index) in groupsInternal"
                    :key="index"
                    class="pa-4 signer-group"
                    color="#f5f5f5"
                    flat
                    rounded
                    @dragover.prevent
                    @drop="(event) => dropMember(event, index)"
            >
              <div class="d-flex justify-space-between align-center mb-2"
                   :class="{'mb-4': index === 0}"
              >
                <div class="font-weight-medium">{{ $t('project.signing-checklist.signer-order.SignersOrderDialog.groupName', { groupOrder: index + 1 }) }}</div>
                <AppButton type="icon"
                           @click="removeGroup(index)"
                >
                  <font-awesome-icon :icon="['fal', 'trash']"></font-awesome-icon>
                </AppButton>
              </div>

              <div v-if="group.length === 0" class="empty-group">
                {{ $t('project.signing-checklist.signer-order.SignersOrderDialog.emptyGroupText') }}
              </div>

              <div class="d-flex flex-wrap" style="gap: 8px">
                <SignerOrderItem v-for="signer in group"
                                 :key="signer"
                                 :signer-id="signer"
                                 :draggable="true"
                                 removable
                                 :group-index="index"
                                 @dragstart="(event) => dragStart(event, signer)"
                                 @remove="(signerToRemove) => removeSignerFromGroup(signerToRemove, index)"
                />
              </div>
            </v-card>
            <v-row>
              <v-col class="text-center">
                <AppButton type="outlined"
                           variant="neutral"
                           @click="addGroup"
                >
                  <font-awesome-icon :icon="['far', 'plus']" class="mr-2"></font-awesome-icon>
                  {{ $t('project.signing-checklist.signer-order.SignersOrderDialog.addNewGroup') }}
                </AppButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <AppTooltip top>
          <template #activator="{attrs, on}">
            <div v-bind="attrs"
                  class="d-inline-block"
                  v-on="on"
            >
              <AppButton type="outlined"
                         variant="neutral"
                         @click="onResetOrderClick"
              >
                {{ $t('common.reset') }}
              </AppButton>
            </div>
          </template>
          <span>{{$t('project.signing-checklist.signer-order.SignersOrderDialog.resetOrderTooltip')}}</span>
        </AppTooltip>
        <div class="d-flex ml-auto column-gap-2">
          <AppButton type="outlined"
                     variant="neutral"
                     @click="onExplicitCloseDialog"
          >
            {{ $t('common.cancel') }}
          </AppButton>
          <AppButton type="primary"
                    :disabled="okDisabled"
                    @click="validateSignersOrder"
          >
            {{ $t('common.confirm') }}
          </AppButton>
        </div>
      </template>
    </AppDialog>
    <AppUnsavedChangesDialog v-if="isUnsavedChangesDialogOpen"
                             @save="onSave"
                             @discard="closeDialog"
                             @close="isUnsavedChangesDialogOpen = false"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'

import AppTooltip from '@/common/AppTooltip'
import AppDialog from '@/common/dialogs/AppDialog'
import AppUnsavedChangesDialog from '@/common/dialogs/AppUnsavedChangesDialog.vue'
import AppButton from '@/design-system/buttons/AppButton'
import { dialogMixin } from '@/mixins/dialogMixin'

import SignerOrderItem from './SignerOrderItem'

export default {
  name: 'SignersOrderDialog',
  components: { AppUnsavedChangesDialog, AppTooltip, AppButton, SignerOrderItem, AppDialog },
  mixins: [dialogMixin],
  props: {
    signers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      groupsInternal: [[]],
      signersToOrder: [],
      hasUnsavedChanges: false,
      isUnsavedChangesDialogOpen: false,
      hasReset: false,
    }
  },
  computed: {
    okDisabled () {
      const groupsWithSignersCounter = this.groupsInternal.filter(group => group.length > 0).length
      return this.signersToOrder.length > 0 || groupsWithSignersCounter < 2
    },
  },
  created () {
    if (this.signers.length >= 2) {
      this.groupsInternal = cloneDeep(this.signers)
    } else {
      this.signersToOrder.push(...this.signers.flat())
    }
    this.$watch(
      'groupsInternal',
      function () {
        this.hasUnsavedChanges = true
      },
      { deep: true },
    )
  },
  methods: {
    confirmClick () {
      const groups = this.groupsInternal.filter(groups => groups.length > 0)
      this.$emit('validateOrder', groups.length ? groups : [this.signers.flat()])
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'success',
        message: this.$t(this.hasReset ? 'project.signing-checklist.signer-order.SignersOrderDialog.confirmResetSignersOrder' : 'project.signing-checklist.signer-order.SignersOrderDialog.confirmSignersOrder'),
      })
      this.hasReset = false
      this.closeDialog()
    },
    addGroup () {
      this.groupsInternal.push([])
    },
    dragStart (event, signer) {
      event.dataTransfer.setData('signer', signer)
    },
    dropMember (event, groupIndex) {
      const data = event.dataTransfer.getData('signer')
      const fromGroup = isNaN(+event.dataTransfer.getData('fromGroup')) ? null : +event.dataTransfer.getData('fromGroup')
      if (data) {
        const signer = +event.dataTransfer.getData('signer')
        if (fromGroup === null) {
          this.groupsInternal[groupIndex].push(signer)
          this.signersToOrder = this.signersToOrder.filter(signerToOrder => signerToOrder !== signer)
        } else {
          this.$set(this.groupsInternal, fromGroup, this.groupsInternal[fromGroup].filter(oldSignerItem => oldSignerItem !== signer))
          this.$set(this.groupsInternal, groupIndex, [...this.groupsInternal[groupIndex], signer])
        }
      }
      this.hasReset = false
    },
    onExplicitCloseDialog () {
      if (this.hasUnsavedChanges) {
        this.isUnsavedChangesDialogOpen = true
      } else {
        this.closeDialog()
      }
    },
    removeGroup (groupIndex) {
      const group = this.groupsInternal[groupIndex]
      group.forEach(member => {
        this.removeSignerFromGroup(member, groupIndex)
      })
      this.groupsInternal.splice(groupIndex, 1)
    },
    onResetOrderClick () {
      this.groupsInternal = [[]]
      this.signersToOrder = this.signers.flat()
      this.hasReset = true
    },
    explicitCloseDialog () {
      if (this.hasUnsavedChanges || this.hasReset) {
        this.isUnsavedChangesDialogOpen = true
      } else {
        this.closeDialog()
      }
    },
    onSave () {
      this.isUnsavedChangesDialogOpen = false
      if (!this.okDisabled || this.groupsInternal.length === 0 || this.hasReset) {
        this.validateSignersOrder()
      }
    },
    removeSignerFromGroup (signerToRemove, groupIndex) {
      this.groupsInternal[groupIndex] = this.groupsInternal[groupIndex].filter(
        signerInGroup => signerInGroup !== signerToRemove,
      )
      if (this.groupsInternal[groupIndex]) { this.signersToOrder.push(signerToRemove) }
    },
    validateSignersOrder () {
      const groups = this.groupsInternal.filter(groups => groups.length > 0)
      this.$emit('patch-signers-order', groups.length ? groups : [this.signers.flat()])
      this.closeDialog()
    },
  },
}
</script>

<style scoped lang="scss">
.SignersOrderDialog {
  &__container {
    max-height: 200px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-height: 300px;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-height: 400px;
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      max-height: 500px;
    }
  }
  &__signers-container,
  &__groups-container {
    max-height: inherit;
    overflow-y: auto;
  }
}
.signer-group {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.empty-group {
  background: rgba(75, 117, 181, .1);
  border: 2px dashed var(--v-tertiary-base);
  border-radius: 4px;
  padding: 20px 60px;
  color: var(--v-tertiary-base);
  text-align: center;
}
</style>
