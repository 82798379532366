<template>
  <span>{{ displayedText }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Text writer animation
 * @displayName AppTextWriter
 * Add writing animation to text
 */
export default defineComponent({
  name: 'AppTextWriter',
  emits: ['end', 'start'],
  props: {
    text: {
      type: String,
      required: true,
    },
    speed: {
      type: Number,
      default: 8, // milliseconds
    },
  },
  data () {
    return {
      displayedText: '',
    }
  },
  mounted () {
    this.startTyping()
  },
  watch: {
    text () {
      this.restartTyping()
    },
  },
  methods: {
    startTyping () {
      this.$emit('start')
      let charIndex = 0
      const intervalId = setInterval(() => {
        if (charIndex < this.text.length) {
          this.displayedText += this.text[charIndex]
          charIndex++
        } else {
          clearInterval(intervalId)
          this.$emit('end')
        }
      }, this.speed)
    },
    restartTyping () {
      this.displayedText = ''
      this.startTyping()
    },
  },
})
</script>
