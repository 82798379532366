<template>
  <div class="ProjectLayout" data-locator="project-settings">
    <h1 class="text-h1 pb-4 ml-1">{{ $t("project.project-settings.layouts.ProjectLayout.projectSettings") }}</h1>
    <p class="pb-4 ml-1 body-1 accent--text text--lighten-2">
      {{ $t("project.project-settings.layouts.ProjectLayout.configureYourProject") }}
      <AppInfoTooltip>
        <p class="mb-0">{{ $t('project.project-settings.layouts.ProjectLayout.projectSettingsInfo1') }}</p>
        <p class="mb-0">{{ $t('project.project-settings.layouts.ProjectLayout.projectSettingsInfo2') }}</p>
      </AppInfoTooltip>
    </p>
    <ProjectSettingsMainSettings :mnemo="mnemo"/>
    <ProjectSettingsAccessRights class="mt-8" :mnemo="mnemo"/>
    <ProjectSettingsFeatures v-if="allowValidationChecklistItemDeletion" class="mt-8" :mnemo="mnemo"/>
    <ProjectSettingsInformation class="mt-8" :mnemo="mnemo"/>
    <ProjectSettingsSignatures class="mt-8" :mnemo="mnemo"/>
    <ProjectSettingsNotifications class="mt-8" :mnemo="mnemo"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppInfoTooltip from '@/common/AppInfoTooltip'
import ProjectSettingsAccessRights from '@/project/project-settings/access-rights/AccessRights.vue'
import ProjectSettingsNotifications from '@/project/project-settings/notifications/ProjectSettingsNotifications.vue'
import ProjectSettingsFeatures from '@/project/project-settings/ProjectSettingsFeatures.vue'
import ProjectSettingsInformation from '@/project/project-settings/ProjectSettingsInformation'
import ProjectSettingsMainSettings from '@/project/project-settings/ProjectSettingsMainSettings'
import ProjectSettingsSignatures from '@/project/project-settings/signatures/ProjectSettingsSignatures.vue'

export default {
  name: 'ProjectLayout',
  components: {
    AppInfoTooltip,
    ProjectSettingsAccessRights,
    ProjectSettingsFeatures,
    ProjectSettingsInformation,
    ProjectSettingsMainSettings,
    ProjectSettingsSignatures,
    ProjectSettingsNotifications,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['allowValidationChecklistItemDeletion']),
  },
}
</script>
